
import { defineComponent } from "vue";

export default defineComponent({
  name: "ApplySuccess",
  components: {},
  setup() {
    return {};
  },
});
