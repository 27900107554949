<template>
  <!-- begin:: Content Body -->
  <div>
    <h2>Success! Your application has been submitted.</h2>
  </div>
  <!-- end:: Content Body -->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ApplySuccess",
  components: {},
  setup() {
    return {};
  },
});
</script>
